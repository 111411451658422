header {
    height: 100vh;
    padding-top: 3rem;
    overflow: hidden;

}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}


.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    /* bottom: 3rem; */
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

.me {
    /* Set initial opacity to 0 */
    opacity: 0;

    /* Rest of the CSS properties... */
    width: 19rem;
    height: 35rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 0rem;
    border-radius: 40px;
    overflow: hidden;
    padding: 3rem 1.5rem 1.5rem 1.5rem;

    /* Animation */
    animation: fade-in 1.8s ease-in-out forwards;
}

.py-image {
    opacity: 0;

    /* Rest of the CSS properties... */
    width: 15rem;
    height: 16rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 12rem;
    margin-left: 2rem;
    border-radius: 40px;
    overflow: hidden;
    padding: 3rem 1.5rem 1.5rem 1.5rem;

    /* Animation */
    animation: fade-in 1.8s ease-in-out forwards;
}

@keyframes fade-in {
    0% {
        opacity: 0;
        /* Start with opacity 0 */
    }

    100% {
        opacity: 1;
        /* End with opacity 1 */
    }
}



.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 27rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* tablets =================================================/*/
@media screen and (max-width: 1024px) {
    .header {
        height: 68vh;
    }
}


/* small devices =================================================/*/
@media screen and (max-width: 600px) {
    .header {
        height: 100vh;
    }

    .header__socials,
    .scroll__down {
        display: none;
    }
}